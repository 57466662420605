import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import Image from "../components/image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import theme from "../theme";

const RatePage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Tarieven" />

        <Typography data-aos="fade-right" variant="h5" style={{ marginBottom: 30, marginTop: 20 }}>
          Tarieven
        </Typography>

        <Grid container data-aos="fade-right">
          <Grid
            item={true}
            xs={12}
            sm={6}
            container
            direction="row"
            alignContent="start"
            justifyContent="left"
            alignItems="flex-start"
          >
            <Typography variant="h6">Wat kost bemiddeling?</Typography>

            <Typography variant="subtitle1" style={{ marginBottom: 30 }}>
              Bemiddeling bespaart u heel wat kosten, zoals bijvoorbeeld kosten voor een advocaat. De tarieven voor
              bemiddeling zijn afhankelijk van verschillende zaken, zoals de diensten die geleverd worden en het aantal
              gepresteerde uren.
            </Typography>
          </Grid>

          <Grid item={true} xs={12} sm={6} container direction="row" justifyContent="center" alignItems="flex-start">
            <div style={{ width: "70%" }}>
              <Image alt="Tarieven" filename="tarieven_pagina.png" />
            </div>
          </Grid>
        </Grid>

        <Typography
          data-aos="fade-left"
          data-aos-delay="150"
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          De wet bepaalt dat de kosten van de bemiddeling en het ereloon van de bemiddelaar in{" "}
          <strong>gelijke delen</strong> dienen te worden verdeeld tussen de betrokken partijen. De partijen kunnen wel
          in onderling overleg bepalen om een andere verdeelsleutel te hanteren. Het bemiddelingsprotocol wordt tijdens
          een eerste consultatie doorgenomen in aanwezigheid van alle partijen. Het bemiddelingsprotocol is het kader
          van afspraken waarbinnen de bemiddeling zal worden gevoerd. Dit zorgt ervoor dat de samenwerking op een
          transparante en duidelijke manier gebeurt.
          <br />
          <br />
          De kosten die rechtstreeks aan een derde partij te betalen zijn, zoals bv. een schatter of een andere
          technische expert, worden door de partijen zelf betaald.
          <br />
          <br />
          <strong>
            Een eerste kennismakingsgesprek per telefoon of e-mail is geheel vrijblijvend en kost u niets.
          </strong>{" "}
          Twijfel dus niet en neem contact op als u vragen heeft.
        </Typography>
        <Grid container data-aos="fade-right">
          <Grid item={true} xs={12} container direction="row" justifyContent="left" alignItems="flex-start">
            <Typography variant="h6" style={{ marginTop: 5 }}>
              Wat kost juridisch advies?
            </Typography>
            <Typography data-aos="fade-right" variant="subtitle1" data-aos-delay="300" style={{ marginBottom: 30 }}>
              Deze tarieven zijn afhankelijk van verschillende zaken, zoals de diensten die geleverd worden en het
              aantal gepresteerde uren.
            </Typography>
          </Grid>
        </Grid>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
);

export default RatePage;
